.container {
  display: flex;
  position: fixed;
  height: 40px;
  width: 100%;
  bottom: 0;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  background-color: #fff;
}
